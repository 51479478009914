/** @jsx jsx */
import { jsx } from 'theme-ui'
import TwitterLogo from '../../images/logos/logo-twitter-blue.svg'
import DribbbleLogo from '../../images/logos/logo-dribbble.svg'
import LinkedInLogo from '../../images/logos/linkedin-icon.svg';
import SvgLogo from './SvgLogo';

const Footer = () => (
  <div>
    <div
      sx={{ pb: '2rem', fontSize: '16px', color: "text" }}
    >
      © 2021 Bitmatica, LLC
    </div>
  </div>
)

export default Footer
