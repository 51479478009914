/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Box } from 'rebass'
import { setConfig } from 'react-hot-loader';
import Nav from "../components/Nav"
import Footer from "../components/Footer"

import '../styles/fonts.css'
import '../styles/global.css'
import './layout.css'

// Doing this here because we want this run at script start-up every time and
// this is the only file that is (at least for now) used by every page.
//  - Rob Nagle (2018-11-29)
setConfig({ pureSFC: true });

const hideOnMobile = `
  display: none;
  @media (min-width: 600px) {
    display: block;
  }
`
const TITLE = 'Bitmatica Joins Credit Karma!'
const DESCRIPTION = 'Today we\'re excited to announce that the Bitmatica team has joined Credit Karma to continue making finance accessible for everyone.'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'title', content: TITLE },
            { name: 'description', content: DESCRIPTION },
            { name: 'twitter:card', content: 'summary_large_image'},
            { name: 'twitter:site', content: '@teambitmatica' },
            { name: 'twitter:title', content: TITLE },
            { name: 'twitter:description', content: DESCRIPTION },
            { name: 'twitter:creator', content: '@teambitmatica' },
            { name: 'twitter:image', content: 'https://www.bitmatica.com/og_image1x.png' },
            { property: 'og:title', content: TITLE },
            { property: 'og:description', content: DESCRIPTION },
            { property: 'og:image', content: 'https://www.bitmatica.com/og_image1x.png' },
            { property: 'og:url', content: 'https://bitmatica.com' },
            { property: 'og:site_name', content: 'Bitmatica' },
            { property: 'og:type', content: 'website' },
            { property: 'og:locale', content: 'en_US' },
            { name: 'msapplication-TileColor', content: "#ffffff" },
            { name: 'msapplication-TileImage', content: "/ms-icon-144x144.png" },
            { name: 'theme-color', content: "#ffffff" }
          ]}
        >
          <html lang='en' />
          <title>{TITLE}</title>
          <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/manifest.json" />
        </Helmet>
        <Box sx={{ maxWidth: '60rem', m: '0 auto', px: ['1.5rem', '1.5rem', '2rem'] }}>
          {/* <Nav /> */}
          {children}
          <Footer />
        </Box>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
